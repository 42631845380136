<script>
import { UiInput } from '@/modules/ui/components';

export default {
  components: { UiInput },

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      block: {},
    };
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.block = { ...this.value };
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', { ...this.block });
    },
  },

  computed: {
    minRelatives: {
      get() {
        if (!this.block['v-on']) {
          return 0;
        }

        return this.block.props.min || 0;
      },

      set(newValue) {
        if (!parseInt(newValue)) {
          this.block.props.min = 0;
          this.block['v-on'] = null;
          return this.emitInput();
        }

        this.block.props.min = parseInt(newValue);
        this.block['v-on'] = {
          count: [
            {
              action: 'page-event',
              event: 'allow-next',
              arguments: `{{ $event >= ${newValue} }}`,
            },
          ],
        };
      },
    },
  },
};
</script>

<template>
  <div class="V3PersonRelativeManagerFace">
    <h2>Administración de familiares</h2>
    <ui-input
      type="text"
      label="Person ID"
      v-model="block.props.personId"
      @input="emitInput()"
    />

    <ui-input
      type="number"
      label="Cantidad mínima de familiares"
      v-model="minRelatives"
      @input="emitInput()"
    />

    <ui-input
      type="number"
      label="Cantidad máxima de familiares"
      v-model="block.props.max"
      @input="emitInput()"
    />
  </div>
</template>

<style lang="scss">
.V3PersonRelativeManagerFace {
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 4px;

  & > h2 {
    margin: 0;
  }
}
</style>